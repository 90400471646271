<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Akt sverka malumotlari</h3>
            </div>

            <div class="card-button d-flex">
              <div class="card-toolbar mr-1">
                <button
                  @click="toExcel"
                  class="btn btn-success font-weight-bolder mr-2"
                  :class="
                    isLoading
                      ? 'spinner spinner-darker-light spinner-right'
                      : ''
                  "
                >
                  {{ $t('BREADCRUMBS.TO_EXCEL') }}
                </button>
              </div>
            </div>
          </div>

          <div class="card-body">
            <table>
              <tr>
                <td colspan="6" class="text-center">
                  Акт Сверки Взаиморасчетов
                </td>
              </tr>
              <tr>
                <td colspan="3" class="text-left">
                  {{ getAktSverka.report_date | formatDate }}
                </td>
                <td colspan="3" class="text-right">г.Ташкент</td>
              </tr>
              <tr>
                <td colspan="6" class="text-center">
                  <div v-if="getAktSverka.company_general_info !== undefined">
                    Мы нижеподписавшиеся, ООО "{{
                      getAktSverka.company_general_info.company_name
                    }}" в лице директора
                    <span v-if="getAktSverka.company_general_info == null">
                      Kiritilmagan
                    </span>
                    <span v-else>
                      {{ getAktSverka.company_general_info.company_direktor }}
                    </span>

                    с одной стороны, и
                    {{ getAktSverka.client_name }} в лице____________ с другой
                    стороны произвели акт сверку заиморасчетов по состоянию
                    {{ getAktSverka.report_start_date | formatDate }} Договору №
                    {{ getAktSverka.contract_reg_number }} от
                    {{ getAktSverka.contract_date }} и выявили следующее
                  </div>
                </td>
              </tr>
              <tr>
                <td rowspan="2">Дата</td>
                <td rowspan="2">Содержание операции</td>
                <td colspan="2">
                  <div v-if="getAktSverka.company_general_info !== undefined">
                    {{ getAktSverka.company_general_info.company_name }}
                  </div>
                </td>
                <td colspan="2">{{ getAktSverka.client_name }}</td>
              </tr>
              <tr>
                <td>Дебет</td>
                <td>Кредит</td>
                <td>Дебет</td>
                <td>Кредит</td>
              </tr>
              <tr class="font-weight-bold">
                <td class="text-left" colspan="2">
                  Сальдо на {{ getAktSverka.report_start_date | formatDate }}
                </td>
                <td>{{ getAktSverka.debet_start_saldo_company | mask }}</td>
                <td>{{ getAktSverka.credit_start_saldo_company | mask }}</td>
                <td>{{ getAktSverka.debet_start_saldo_client | mask }}</td>
                <td>{{ getAktSverka.credit_start_saldo_client | mask }}</td>
              </tr>

              <tr v-for="itemm in getAktSverka.akt_sverka_body" :key="itemm.id">
                <!-- <td>{{ getAktSverka.report_date }}322</td> -->
                <td>{{ itemm.oper_date }}</td>
                <td>{{ itemm.comment }}</td>
                <td>{{ itemm.debet_company | mask }}</td>
                <td>{{ itemm.credit_company | mask }}</td>
                <td>{{ itemm.debet_client | mask }}</td>
                <td>{{ itemm.credit_client | mask }}</td>
              </tr>
              <tr>
                <td class="text-left" colspan="2">Итого оборотов</td>
                <td>{{ getAktSverka.sum_debet_end_saldo_company | mask }}</td>
                <td>{{ getAktSverka.sum_credit_company | mask }}</td>
                <td>{{ getAktSverka.sum_debet_client | mask }}</td>
                <td>{{ getAktSverka.sum_credit_end_saldo_client | mask }}</td>
              </tr>

              <tr class="font-weight-bold">
                <td class="text-left" colspan="2">
                  Сальдо на {{ getAktSverka.report_end_date | formatDate }}
                </td>
                <td>{{ getAktSverka.debet_end_saldo_company | mask }}</td>
                <td>{{ getAktSverka.credit_end_saldo_company | mask }}</td>
                <td>{{ getAktSverka.debet_end_saldo_client | mask }}</td>
                <td>{{ getAktSverka.credit_end_saldo_client | mask }}</td>
              </tr>
              <tr>
                <th colspan="3" class="text-right">
                  <div v-if="getAktSverka.company_general_info !== undefined">
                    {{ getAktSverka.company_general_info.company_name }}
                  </div>
                </th>
                <th colspan="3" class="text-right">
                  {{ getAktSverka.client_name }}
                </th>
              </tr>
              <tr>
                <td colspan="3" class="text-right">
                  <div v-if="getAktSverka.company_general_info == null">
                    Kiritilmagan
                  </div>
                  <div v-else>
                    <p>
                      р/с №
                      {{ getAktSverka.company_general_info.bank_account }}
                    </p>
                    <p>
                      отд. г. Ташкент Чаб "{{
                        getAktSverka.company_general_info.bank_name
                      }}"
                    </p>
                    <p>
                      МФО -
                      {{ getAktSverka.company_general_info.bank_mfo }} ИНН-{{
                        getAktSverka.company_general_info.company_inn
                      }}
                    </p>
                  </div>
                </td>
                <td colspan="3" class="text-right">
                  <p>ИНН - {{ getAktSverka.client_inn }}</p>

                  <p>
                    Bank mfo -
                    {{ getAktSverka.client_bank_code }}
                  </p>
                  <p>Адрес - {{ getAktSverka.client_address }}</p>
                  <p>
                    Bank hisob raqami - {{ getAktSverka.client_bank_account }}
                  </p>
                  <p>
                    Shartnoma reg raqami -
                    {{ getAktSverka.contract_reg_number }}
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import axios from 'axios'

export default {
  data() {
    return {}
  },
  beforeCreate() {
    this.$store.dispatch('getAllActRecontDetails', this.$route.params.id)
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Mijozlar' },
      { title: 'Hisob faktura' },
      { title: 'Akt sverka malumotlari' }
    ])
  },
  filters: {
    mask(val) {
      // console.log(val)
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) return val.toLocaleString('es-US')
      else return val
    }
  },
  computed: {
    getAktSverka() {
      return this.$store.state.requests.ActRecontDetails
    }
  },

  methods: {
    toExcel() {
      this.$store.commit('setIsLoading', true)

      axios({
        method: 'get',
        url: 'reports/report6/excel/' + this.$route.params.id + '/',
        responseType: 'blob',
        data: this.dataToExcel
      }).then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Zayavka')
        document.body.appendChild(link)
        link.click()
        this.$store.commit('setIsLoading', false)
      })
    }
  }
}
</script>

<style scoped>
p {
  margin-bottom: 0;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}
table td {
  text-align: center;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 10px;
}
</style>
